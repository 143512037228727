import React from 'react';
import Loadable from 'react-loadable';
import Loading from 'components/common/loading';
import { DEFINED_PAGE_CODES } from '../../Naming';
import getUser from '../../../common/auth';

const StudentSchedule = Loadable({
  loader: () => import('components/timetable_v2/viewers/ScheduleOverview'),
  loading: Loading,
});

export default {
  [DEFINED_PAGE_CODES.defaultPage]: (props) => {
    const userInfo = getUser();
    return (
      <StudentSchedule
        userIid={userInfo && userInfo.info && userInfo.info.iid}
        autoSearchWhenValuesChange
        hiddenSubmitButton
      />
    );
  },
};
