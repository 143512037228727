import React from 'react';
import Loadable from 'react-loadable';
import Loading from 'components/common/loading';
import { DEFINED_PAGE_CODES } from '../../../Naming';

const UpdateUser = Loadable({
  loader: () =>
    import(/* webpackChunkName: "homepage" */ 'components/front-end/user/update/Layout'),
  loading: Loading,
});

export default {
  [DEFINED_PAGE_CODES.defaultPage]: (props) => (
    <UpdateUser {...props} updateType="info" blogRouterId="site" />
  ),
};
