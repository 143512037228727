import React from 'react';
import Loadable from 'react-loadable';
import Loading from 'components/common/loading';
import { DEFINED_PAGE_CODES } from '../../Naming';

const VietEdHomePage = Loadable({
  loader: () => import('components/front-end/homepage/vieted'),
  loading: Loading,
});
const LotusHomePage = Loadable({
  loader: () => import('components/front-end/homepage/lotus'),
  loading: Loading,
});

const umsHome = Loadable({
  loader: () =>
    import(/* webpackChunkName: "homepage" */ 'components/front-end/dashboard/ums/home'),
  loading: Loading,
});

export default {
  [DEFINED_PAGE_CODES.LotusHomePage]: LotusHomePage,
  [DEFINED_PAGE_CODES.LotusHomePage]: LotusHomePage,
  [DEFINED_PAGE_CODES.VietEdHomePage]: VietEdHomePage,
  [DEFINED_PAGE_CODES.defaultHomePageUMS]: umsHome,
};
