import React from 'react';
import Loadable from 'react-loadable';
import Loading from 'components/common/loading';
import { DEFINED_PAGE_CODES } from '../../Naming';

const OverviewCourses = Loadable({
  loader: () =>
    import(/* webpackChunkName: "homepage" */ 'components/front-end/dashboard/show-by-tab/courses/overview'),
  loading: Loading,
});

const umsHome = Loadable({
  loader: () =>
    import(/* webpackChunkName: "homepage" */ 'components/front-end/dashboard/ums/home'),
  loading: Loading,
});

export default {
  [DEFINED_PAGE_CODES.defaultDashboardPageLMS]: OverviewCourses,
  [DEFINED_PAGE_CODES.defaultDashboardPageUMS]: umsHome,
};
