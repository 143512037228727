import React from 'react';
import Loadable from 'react-loadable';
import Loading from 'components/common/loading';
import { DEFINED_PAGE_CODES } from '../../../Naming';

const PublicCourses = Loadable({
  loader: () =>
    import(/* webpackChunkName: "homepage" */ 'components/front-end/dashboard/show-by-tab/courses/public'),
  loading: Loading,
});

export default {
  [DEFINED_PAGE_CODES.defaultPage]: PublicCourses,
};
