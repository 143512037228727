/**
 * Created by Peter Hoang Nguyen on 4/2/2017.
 */
import axios from 'axios';
import Store from 'store';
import actions from 'actions/node/creators';
import errorCodes from 'common/errorCodes';
import common from './Common';
import { logout } from 'actions/auth';
import { GetflyApiKey } from 'configs/header-keys';
import todayConvert from 'configs/today-convert';
import xml_xuat_hoa_don from 'components/admin/financial/mainstage/invoices/invoice-release/xml-layouts/xuat-hoa-don';
import { amount_in_numbers_to_words_vnd } from 'common';
import apiUrls from 'api-endpoints';

const PROGRESS_PROCESS = 0.8;

const checkGoogleAuthenticatorError = (ret) => {
  const errorCode = ret.err_code;

  switch (errorCode) {
    case errorCodes.ERR_GA_REQUIRED:
    case errorCodes.ERR_GA_SETUP_REQUIRED:
      Store.dispatch(actions.handleGoogleAuthenticatorDialog(errorCode));

      break;
    case errorCodes.ERR_AUTHENTICATE_FAILED:
      Store.dispatch(logout());

      break;
  }
};
axios.interceptors.request.use(
  (config) => config,
  (error) =>
    // //console.log("before request: not success");
    Promise.reject(error),
);

// Add a response interceptor
axios.interceptors.response.use(
  (response) =>
    // Do something with response data
    // //console.log("response: success");
    response,
  (error) =>
    // Do something with response error
    // //console.log("response: not success");
    Promise.reject(error),
);

class Request {
  constructor() {
    //Init cancelToken. Note: Must create cancel token for each request
    this.cancelToken = axios.CancelToken;
    this.source = this.cancelToken.source();
  }

  get(url, params) {
    const { urlProcess, allParams } = common.getURL(url, params);
    if (window.NProgress) {
      window.NProgress.start();
      window.NProgress.set(PROGRESS_PROCESS);
    }
    return axios
      .get(urlProcess, {
        params: allParams,
      })
      .then((response) => {
        const ret = response.data;
        if (!ret.success) checkGoogleAuthenticatorError(ret);
        if (window.NProgress) {
          window.NProgress.done();
        }
        return ret;
      })
      .catch((error) => {
        if (window.NProgress) {
          window.NProgress.done();
        }
      });
  }

  post(url, params, config, showProgress = true) {
    const { urlProcess, allParams } = common.getURL(url, params);
    const formPost = common.createFrom(allParams);
    if (showProgress && window.NProgress) {
      window.NProgress.start();
      window.NProgress.set(PROGRESS_PROCESS);
    }
    return axios
      .post(urlProcess, formPost, config)
      .then((response) => {
        const ret = response.data;
        if (!ret.success) checkGoogleAuthenticatorError(ret);
        if (window.NProgress) {
          window.NProgress.done();
        }
        return ret;
      })
      .catch((error) => {
        if (window.NProgress) {
          window.NProgress.done();
        }
      });
  }

  postExternal(url, params, showProgress = true) {
    const { urlProcess, allParams } = common.getURL(url, params);
    const formPost = common.createFrom(allParams);
    if (showProgress && window.NProgress) {
      window.NProgress.start();
      window.NProgress.set(PROGRESS_PROCESS);
    }
    return axios
      .post(urlProcess, formPost)
      .then((response) => {
        const ret = response.data;
        if (!ret.success) checkGoogleAuthenticatorError(ret);
        if (window.NProgress) {
          window.NProgress.done();
        }
        return ret;
      })
      .catch((error) => {
        if (window.NProgress) {
          window.NProgress.done();
        }
      });
  }

  putExternal(url, params, showProgress = true) {
    const { urlProcess, allParams } = common.getURL(url, params);
    const formPost = common.createFrom(allParams);
    if (showProgress && window.NProgress) {
      window.NProgress.start();
      window.NProgress.set(PROGRESS_PROCESS);
    }
    return axios
      .put(urlProcess, formPost, { headers: GetflyApiKey })
      .then((response) => {
        const ret = response.data;
        if (!ret.success) checkGoogleAuthenticatorError(ret);
        if (window.NProgress) {
          window.NProgress.done();
        }
        return ret;
      })
      .catch((error) => {
        if (window.NProgress) {
          window.NProgress.done();
        }
      });
  }

  deleteExternal(url, params, showProgress = true) {
    const { urlProcess, allParams } = common.getURL(url, params);
    const formPost = common.createFrom(allParams);
    if (showProgress && window.NProgress) {
      window.NProgress.start();
      window.NProgress.set(PROGRESS_PROCESS);
    }
    return axios
      .delete(urlProcess, formPost, { headers: GetflyApiKey })
      .then((response) => {
        const ret = response.data;
        if (!ret.success) checkGoogleAuthenticatorError(ret);
        if (window.NProgress) {
          window.NProgress.done();
        }
        return ret;
      })
      .catch((error) => {
        if (window.NProgress) {
          window.NProgress.done();
        }
      });
  }

  releaseInvoice(url, params, school, item, nonce, taxcode, auth) {
    const { urlProcess, allParams } = common.getURL(url, params);
    const today = todayConvert();
    const xmlData = xml_xuat_hoa_don(
      nonce,
      '',
      item.student.name,
      String(school.name.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '-')),
      school.school_email,
      school.address,
      school.hotline,
      taxcode,
      'TM/CK',
      today,
      String(
        item.fees[0].fee_template.name.replace(
          /[&\/\\#,+()$~%.'":*?<>{}]/g,
          '-',
        ),
      ),
      'Lần',
      '1',
      item.fees[0].amount_to_pay,
      item.fees[0].amount_to_pay,
      item.fees[0].amount_to_pay,
      '',
      '-1',
      '0',
      item.fees[0].amount_to_pay,
      `${amount_in_numbers_to_words_vnd(item.fees[0].amount_to_pay)} đồng`,
    );

    const formPost = {
      xmlData: xmlData,
      pattern: '01GTKT0/001',
      convert: false,
    };
    const additionHeader = {
      TaxCode: taxcode,
      Authentication: auth,
      'Access-Control-Allow-Origin': '*',
    };
    return axios
      .post(urlProcess, formPost, { headers: additionHeader })
      .then((response) => {
        const ret = response.data;
        if (!ret.success) checkGoogleAuthenticatorError(ret);
        if (window.NProgress) {
          window.NProgress.done();
        }
        const res = ret.data[0];
        // Chuẩn bị dữ liệu trả về  để  thêm vào database
        Object.assign(allParams, res);
        allParams['invoice_iid'] = item.iid;
        // Post request
        const { urlProcess } = common.getURL(apiUrls.add_data_invoice_release);
        const paramsProcess = common.createFrom(allParams);
        axios.post(urlProcess, paramsProcess);
        //
        return ret;
      })
      .catch((error) => {
        if (window.NProgress) {
          window.NProgress.done();
        }
      });
  }

  getPDFInvoice(url, taxcode, auth, showProgress = true) {
    const addition_header = { TaxCode: taxcode, Authentication: auth };
    if (showProgress && window.NProgress) {
      window.NProgress.start();
      window.NProgress.set(PROGRESS_PROCESS);
    }
    return axios
      .get(url, { headers: addition_header })
      .then((response) => {
        const ret = response.data;
        if (!ret.success) checkGoogleAuthenticatorError(ret);
        if (window.NProgress) {
          window.NProgress.done();
        }
        return ret;
      })
      .catch((error) => {
        if (window.NProgress) {
          window.NProgress.done();
        }
      });
  }

  //Cancel request by token request
  actionCancel() {
    this.source.cancel('Operation canceled by the user.');
  }
}

export default new Request();
