import Loadable from 'components/common/async-loader/Loadable';
import { LayoutRegistered } from './LayoutHelper';

const Learning = Loadable({
  loader: () =>
    import(/* webpackChunkName: "learning-layout" */ 'layouts/learning/WebApp'),
});
const Admin = Loadable({
  loader: () =>
    import(/* webpackChunkName: "admin-layout" */ 'layouts/admin_v2'),
});
const FrontendTheme = Loadable({
  loader: () => import(/* webpackChunkName: "frontend-theme" */ 'themes/index'),
});
const VietEdTheme = Loadable({
  loader: () =>
    import(/* webpackChunkName: "vieted-layout" */ 'layouts/vieted-theme'),
});
const LotusTheme = Loadable({
  loader: () =>
    import(/* webpackChunkName: "lotus-layout" */ 'layouts/lotus-theme'),
});
const Embedded = Loadable({
  loader: () =>
    import(/* webpackChunkName: "embedded-layout" */ 'layouts/embedded'),
});
const UMSTheme = Loadable({
  loader: () =>
    import(/* webpackChunkName: "ums-layout" */ 'layouts/ums-theme'),
});

export default {
  [LayoutRegistered.embedded]: {
    component: Embedded,
  },
  [LayoutRegistered.frontend]: {
    component: FrontendTheme,
  },
  [LayoutRegistered.learning]: {
    component: Learning,
  },
  [LayoutRegistered.admin]: {
    component: Admin,
  },
  [LayoutRegistered.vieted]: {
    component: VietEdTheme,
  },
  [LayoutRegistered.lotus]: {
    component: LotusTheme,
  },
  [LayoutRegistered.ums]: {
    component: UMSTheme,
  },
  // [LayoutRegistered.mobile]: {
  //   component: MobileTheme,
  // },
};
