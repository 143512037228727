import { put, takeEvery } from 'redux-saga/effects';
import {
  SET_CURRENT_QUESTION_IN_EXERCISE,
  setCurrentQuestionInExerciseFinished,
} from 'actions/learn/exercise/normal/saga-creators';
import { saveItemInfo, saveItemQuestionInfo } from 'actions/learn';
import { replace } from 'react-router-redux';

export default function* setCurrentQuestionSaga() {
  yield takeEvery(
    SET_CURRENT_QUESTION_IN_EXERCISE,
    function* setCurrentQuestion(action) {
      const {
        itemIid,
        questionUniqueId,
        shouldDisplayCurrentQuestionAtTop,
      } = action;

      yield put(replace({ search: questionUniqueId }));

      yield put(
        saveItemInfo(itemIid, {
          shouldDisplayCurrentQuestionAtTop,
        }),
      );

      if (questionUniqueId) {
        yield put(
          saveItemQuestionInfo(itemIid, questionUniqueId, { isTouched: true }),
        );
      }

      yield put(setCurrentQuestionInExerciseFinished(itemIid));
    },
  );
}
