import React from 'react';
import Loadable from 'react-loadable';
import Loading from 'components/common/loading';
import { DEFINED_PAGE_CODES } from '../../../Naming';

const assignedCourses = Loadable({
  loader: () =>
    import(/* webpackChunkName: "courses/assigned" */ 'components/front-end/dashboard/show-by-tab/courses/assigned'),
  loading: Loading,
});

export default {
  [DEFINED_PAGE_CODES.defaultPage]: assignedCourses,
};
