import { call, put, takeEvery } from 'redux-saga/effects';
import apiUrls from 'api-endpoints';
import { t1 } from 'translate';
import actions from 'actions/creators';
import nodeActions from 'actions/node/creators';
import { loadingStatuses } from 'configs/constants';
import formSubmitFunction from 'sagas/node/common/formSubmitFunction';

//= ======================fetchNodeSaga=================================
function* apiGetData(action) {
  const { params, config } = action;
  const { keyState } = config;
  let { url } = config;
  url = url || apiUrls.fetch_node;

  const actionToSetLoadingStatusToSet = (status) =>
    actions.changeLoadingStatus({
      status,
      [keyState]: status,
    });

  try {
    yield put(actionToSetLoadingStatusToSet(loadingStatuses.LOADING));

    const options = {
      successMessage: config.successMessage,
      failureMessage: config.failureMessage,
      url,
      extraParams: params,
      executeOnSuccess: (data) =>
        config.executeOnSuccess &&
        config.executeOnSuccess(data.result, data.objects),
      executeOnFailure: config.executeOnFailure,
    };

    const method = config.post ? 'post' : 'get';
    const data = yield call(
      formSubmitFunction,
      keyState,
      options,
      method,
      false,
    );

    if (data && data.success && data.result && keyState) {
      yield put(nodeActions.updateDataApiRequestResults(data.result, keyState));
    }

    if (data && data.success && data.result && config && config.upsertNode) {
      yield put(actions.treeUpsertNodes(data.result, 'search'));
    }

    if (!data || !data.success || !data.result) {
      yield put(nodeActions.updateDataApiRequestResults(null, keyState));
    }

    if (config.showMessage && data && data.message) {
      yield put(
        nodeActions.snackbar(data.success ? 'success' : 'error', data.message),
      );
    }

    yield put(actionToSetLoadingStatusToSet(loadingStatuses.FINISHED));
  } catch (e) {
    yield put(actionToSetLoadingStatusToSet(loadingStatuses.FINISHED));
    //console.log(t1('network_request_error'));
  }
}

export default function* apiGetDataSaga() {
  yield takeEvery('API_GET_DATA_REQUEST', apiGetData);
}
