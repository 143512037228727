import React from 'react';
import PropTypes from 'prop-types';
import { t1 } from 'translate';
import { connect } from 'react-redux';
import { getThemeConfig } from 'utils/selectors';
import { reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';
import AuthPanel from 'components/user/auth/AuthPanel';
import TextField from 'schema-form/elements/redux-form-fields/MuiTextField';
import Checkbox from 'schema-form/elements/redux-form-fields/MuiCheckbox';
import { layouts } from 'configs/constants';
import {
  activeLoginTab,
  activeRegisterTab,
  closeLoginDialog,
} from 'actions/auth/auth-dialog';
import { getRootUrl } from 'routes/common';
import UmsLogin from './ums/index';

import MobileSignIn from './mobile/index';

import '../stylesheet.scss';
import SocialAuth from '../social-auth/SocialAuth';
import LoginAction from './LoginAction';

class Login extends LoginAction {
  textFieldHintStyle = { padding: '6px 12px' };
  textFieldUnderlineStyle = { display: 'none' };
  checkboxLabelStyle = { color: '#9d9d9d' };
  checkboxIconStyle = { fill: '#9d9d9d' };

  componentWillMount() {
    const { dispatch } = this.props;
    dispatch(activeLoginTab());
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.doLogin();
    }
  };

  changePassword = () => {
    const { dispatch, history } = this.props;

    dispatch(closeLoginDialog());
    history.push('/forgot-password');
  };

  doLogin() {
    const { loginForm, themeConfig, dispatch, history } = this.props;
    if (loginForm && loginForm.values) {
      this.doLoginAction(loginForm.values, themeConfig, dispatch, history);
    }
  }

  renderContent = (props) => {
    const { themeConfig, rootUrl, dispatch, enableRegisters, history } = props;
    let content = <div />;
    if (rootUrl === 'mobile') {
      content = (
        <MobileSignIn
          doLogin={() => {
            this.doLogin();
          }}
          changePassword={this.changePassword}
          handleKeyPress={this.handleKeyPress}
        />
      );
    } else if (themeConfig.type === 'sis') {
      content = (
        <UmsLogin
          doLogin={() => {
            this.doLogin();
          }}
          changePassword={this.changePassword}
          handleKeyPress={this.handleKeyPress}
        />
      );
    }
    return content;
  };
  render() {
    return this.renderContent(this.props);
  }
}

Login.childContextTypes = {
  muiTheme: PropTypes.shape().isRequired,
};
const populateStateToProps = (state, props) => {
  const userInfo = state.user.info;
  return {
    rootUrl: getRootUrl(props),
    loginForm: state.form.login,
    userInfo,
    enableRegisters:
      state.domainInfo &&
      state.domainInfo.conf &&
      state.domainInfo.conf.enable_registers,
    themeConfig: getThemeConfig(state),
  };
};

export default connect(populateStateToProps)(
  reduxForm({
    form: 'login', // a unique identifier for this form
  })(withRouter(Login)),
);
