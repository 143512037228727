import React from 'react';
import PropTypes from 'prop-types';
import { t1 } from 'translate';

const getComponentByConfig = (themeId, configs) => {
  if (!configs || !configs[themeId]) {
    return () => <div>{t1('page not found')}</div>;
  }
  return configs[themeId];
};

/**
 * Created by Peter Hoang Nguyen
 *
 * Email: vntopmas@gmail.com
 * Tel: 0966298666
 * created date 05/12/2017
 **/
class ThemeHelper extends React.Component {
  render() {
    const { themeId, config, pageCode } = this.props;
    let key = themeId || pageCode;

    const Component = getComponentByConfig(key, config);

    return (
      <Component {...this.props} themeId={key} pageCode={key}>
        {this.props.children}
      </Component>
    );
  }
}

ThemeHelper.propTypes = {
  themeId: PropTypes.string,
  config: PropTypes.object,
};

export default ThemeHelper;
