import React from 'react';
import Loadable from 'react-loadable';
import Loading from 'components/common/loading';
import { DEFINED_PAGE_CODES } from '../../Naming';

const umsAttendance = Loadable({
  loader: () =>
    import(/* webpackChunkName: "homepage" */ 'components/front-end/dashboard/ums/attendance'),
  loading: Loading,
});

export default {
  [DEFINED_PAGE_CODES.defaultPage]: umsAttendance,
  [DEFINED_PAGE_CODES.defaultAttendancePage]: umsAttendance,
};
