import React from 'react';
import Loadable from 'react-loadable';
import getUser from 'common/auth';
import Loading from 'components/common/loading';
import { DEFINED_PAGE_CODES } from '../../Naming';

// const userRequest = Loadable({
//   loader: () => import(/* webpackChunkName: "homepage" */ 'themes/default/components/request'),
//   loading: Loading,
// });

const UserRequest = Loadable({
  loader: () =>
    import(/* webpackChunkName: "homepage" */ 'components/admin/req/search'),
  loading: Loading,
});

const defaultUserRequest = (props) => {
  const userInfo = getUser();

  return (
    <UserRequest
      hiddenFields={{
        requester_iid: userInfo && userInfo.info && userInfo.info.iid,
      }}
    />
  );
};

export default {
  [DEFINED_PAGE_CODES.defaultPage]: defaultUserRequest,
};
