import { DEFINED_PAGE_CODES, PAGE_TYPES, THEME } from './Naming';

export const SHARED_PAGE_CODES = [
  DEFINED_PAGE_CODES.defaultPage,
  DEFINED_PAGE_CODES.defaultCourseRegistration,
  DEFINED_PAGE_CODES.defaultAssignmentPage,
  DEFINED_PAGE_CODES.defaultAttendanceManagerPage,
  DEFINED_PAGE_CODES.defaultAttendancePage,
  DEFINED_PAGE_CODES.defaultBlogPage,
];

export const DEFAULT_PAGE_CODES_LMS = [
  ...SHARED_PAGE_CODES,
  DEFINED_PAGE_CODES.defaultDashboardPageLMS,
  DEFINED_PAGE_CODES.defaultHomePageLMS,
];

export const DEFAULT_PAGE_CODES_UMS = [
  ...SHARED_PAGE_CODES,
  DEFINED_PAGE_CODES.defaultDashboardPageUMS,
  DEFINED_PAGE_CODES.defaultHomePageUMS,
];

export const DEFAULT_PAGE_CODES_PIXELZ = [
  ...SHARED_PAGE_CODES,
  DEFINED_PAGE_CODES.defaultDashboardPageLMS,
  DEFINED_PAGE_CODES.PixelzHomePage,
];

export const DEFAULT_PAGE_CODES_VIETED = [
  ...SHARED_PAGE_CODES,
  DEFINED_PAGE_CODES.defaultDashboardPageLMS,
  DEFINED_PAGE_CODES.VietEdHomePage,
];

export const DEFAULT_PAGE_CODES_LOTUS = [
  ...SHARED_PAGE_CODES,
  DEFINED_PAGE_CODES.defaultDashboardPageLMS,
  DEFINED_PAGE_CODES.LotusHomePage,
];

export const DEFAULT_PAGE_CODES_ETEC = [
  ...SHARED_PAGE_CODES,
  DEFINED_PAGE_CODES.defaultDashboardPageLMS,
  DEFINED_PAGE_CODES.EtecHomePage,
];

export const DEFAULT_PAGE_CODES_XPEAK = [
  ...SHARED_PAGE_CODES,
  DEFINED_PAGE_CODES.defaultDashboardPageLMS,
  DEFINED_PAGE_CODES.XpeakHomePage,
];

export const DEFAULT_PAGE_CODES_EVN = [
  ...SHARED_PAGE_CODES,
  DEFINED_PAGE_CODES.defaultDashboardPageLMS,
  DEFINED_PAGE_CODES.EVNHomePage,
];

export const PAGES_OF_THEME = {
  [THEME.default]: DEFAULT_PAGE_CODES_LMS,
  [THEME.ums]: DEFAULT_PAGE_CODES_UMS,
  [THEME.vt]: DEFAULT_PAGE_CODES_EVN,
  [THEME.evn]: DEFAULT_PAGE_CODES_EVN,
};
