import React from 'react';
import Loadable from 'react-loadable';
import Loading from 'components/common/loading';
import { DEFINED_PAGE_CODES } from '../../Naming';

const major = Loadable({
  loader: () =>
    import(/* webpackChunkName: "homepage" */ 'components/front-end/dashboard/ums/major'),
  loading: Loading,
});

export default {
  [DEFINED_PAGE_CODES.defaultPage]: major,
};
