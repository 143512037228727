import React from 'react';
import PageLayout from 'layouts/helpers/ThemeHelper';
import connect from 'react-redux/es/connect/connect';
import { PAGES_OF_THEME } from '../pages-of-theme';
import { getThemeConfig } from '../../utils/selectors';
import { DEFINED_PAGE_CODES } from '../Naming';

/**
 * Mỗi themeIid sẽ có 1 số các pageCode gắn vào. Nguyên tắc là mỗi pageType chỉ có 1 pageCode gắn vào theme.
 *
 * @param pagesOfTheme
 * @param configs
 */
const getPageBy = (pageCodesOfTheme, configs) => {
  if (!configs || Object.keys(configs).length === 0) {
    return () => <div>Page not found.</div>;
  }

  if (!pageCodesOfTheme || pageCodesOfTheme.length === 0) {
    return configs[Object.keys(configs)[0]]; // lấy page đầu tiên
  }

  for (let i = 0; i < pageCodesOfTheme.length; i++) {
    const pageCode = pageCodesOfTheme[i];
    //console.log('pageCode, configs[pageCode]', pageCode, configs[pageCode]);
    if (configs[pageCode]) {
      return configs[pageCode]; // nếu tồn tại config của pageCode thì lấy ra
    }
  }

  if (configs[DEFINED_PAGE_CODES.defaultPage]) {
    return configs[DEFINED_PAGE_CODES.defaultPage]; // nếu có default thì lấy page với code là default
  }

  return configs[Object.keys(configs)[0]]; // lấy page đầu tiên để hiển thị
};

class HelperComponent extends React.Component {
  render() {
    const { themeConfig } = this.props;
    const theme = this.props.theme || {};
    const route = this.props.route || {};

    const pageCodesOfTheme = PAGES_OF_THEME[themeConfig.layout];
    // const pageCodesOfTheme = PAGES_OF_THEME['vt'];
    const Page = getPageBy(pageCodesOfTheme, route.configRegister);

    return <Page {...this.props}>{this.props.children}</Page>;
  }
}

const mapStateToProps = (state) => {
  const domain = state.domain || {};
  return {
    themeConfig: getThemeConfig(state),
  };
};
export default connect(mapStateToProps)(HelperComponent);
