import { fork } from 'redux-saga/effects';
import examFlow from './examFlow';
import exerciseFlow from './exerciseFlow';
import surveyFlow from './surveyFlow';
import setCurrentQuestionInExercise from './setCurrentQuestion';

const exerciseSagas = [
  fork(examFlow),
  fork(exerciseFlow),
  fork(surveyFlow),
  fork(setCurrentQuestionInExercise),
];

export default exerciseSagas;
