import { put, take } from 'redux-saga/effects';
import {
  SET_CURRENT_QUESTION_IN_EXERCISE_FINISHED,
  setCurrentQuestionInExercise,
} from 'actions/learn/exercise/normal/saga-creators';

function* setCurrentQuestion(
  itemIid,
  questionUniqueId,
  shouldDisplayCurrentQuestionAtTop,
) {
  yield put(
    setCurrentQuestionInExercise(
      itemIid,
      questionUniqueId,
      shouldDisplayCurrentQuestionAtTop,
    ),
  );
  yield take(
    (action) =>
      action.type === SET_CURRENT_QUESTION_IN_EXERCISE_FINISHED &&
      String(action.itemIid) === String(itemIid),
  );
}

export default setCurrentQuestion;
