import React from 'react';
import Loadable from 'react-loadable';
import Loading from 'components/common/loading';
import { DEFINED_PAGE_CODES } from '../../Naming';

const umsAttendanceManager = Loadable({
  loader: () =>
    import(/* webpackChunkName: "umsAttendanceManager" */ 'components/admin/course/mainstage/attendance/Layout'),
  loading: Loading,
});
export default {
  [DEFINED_PAGE_CODES.defaultPage]: umsAttendanceManager,
  [DEFINED_PAGE_CODES.defaultAttendanceManagerPage]: umsAttendanceManager,
};
