export const commonActionTypes = {
  SET_STATUS_FORM_VIEW: 'SET_STATUS_FORM_VIEW',
};

export default {
  setStatusOfFormView: (params) => ({
    type: commonActionTypes.SET_STATUS_FORM_VIEW,
    params,
  }),
};
