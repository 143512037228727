import { call, put, takeEvery } from 'redux-saga/effects';
import Requester from 'common/network/http/Request';
import apiUrls from 'api-endpoints';
import { SAVE_SURVEY_TAKE_REQUEST } from 'actions/learn/saga-creators';
import { steps } from 'common/learn/exercise';
import { saveItemInfo } from 'actions/learn';

function* saveTake(action) {
  const { itemIid, params } = action;
  if (!params) return;

  try {
    const data = yield call(Requester.post, apiUrls.save_survey_take, params);
    if (data && data.success) {
      yield put(
        saveItemInfo(itemIid, {
          step: steps.FINISHED,
        }),
      );
    }
  } catch (ex) {
    //console.log('save take request failed', ex);
  }
}

export default function* saveTakeSaga() {
  yield takeEvery(SAVE_SURVEY_TAKE_REQUEST, saveTake);
}
